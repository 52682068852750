import React from 'react';
import './SearchBar.css';

class SearchBar extends React.Component {
	constructor(props){
		super(props);
		this.search = this.search.bind(this);
		this.handleTermChange = this.handleTermChange.bind(this);
		this.state = {
			term: ''
		}
	}
	search(){
		//console.log('Search method triggered');
		this.props.onSearch( this.state.term );
	}
	handleTermChange(e){
		this.setState({ term: e.target.value });
		//console.log('handleTermChange triggered. New value: ' + e.target.value);
	}
	render(){
		return (
			<div className="SearchBar">
				<input placeholder="Enter A Song, Album, or Artist" onChange={this.handleTermChange} />
				<button onClick={this.search}>SEARCH</button>
			</div>
		);
	}
}

export default SearchBar;